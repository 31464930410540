import { render, staticRenderFns } from "./CnHome2.vue?vue&type=template&id=ccb662ae&scoped=true&"
import script from "./CnHome2.vue?vue&type=script&lang=js&"
export * from "./CnHome2.vue?vue&type=script&lang=js&"
import style0 from "./CnHome2.vue?vue&type=style&index=0&id=ccb662ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccb662ae",
  null
  
)

export default component.exports